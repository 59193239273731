import { type TunaPlugin } from '@tunasong/plugin-lib'
import invariant from 'tiny-invariant'
import { type PluginCollectionConfig, type PluginType } from './plugin-types.js'
import { rawPlugins } from './plugins.js'

export const configurePlugins = async (config: PluginCollectionConfig = {}) => {
  const configuredPlugins: TunaPlugin[] = []

  for (const [key, pluginCreator] of rawPlugins) {
    // basePlugin is either the plugin or the plugin creator's default export
    const pluginConfig = config[key as PluginType]

    if (!Boolean(pluginConfig)) {
      continue
    }
    if (pluginCreator instanceof Promise) {
      const creator = (await pluginCreator).default
      const config = typeof pluginConfig === 'object' && pluginConfig !== null ? pluginConfig : undefined
      const configuredPlugin = creator(config)
      invariant(
        configuredPlugin.key === key,
        `Plugin key ${key} must match the key of the plugin creator ${configuredPlugin.key}`
      )
      configuredPlugins.push(configuredPlugin as TunaPlugin)
    } else {
      configuredPlugins.push(pluginCreator)
    }
  }

  return configuredPlugins
}
