import { isOnline, isString, logger } from '@tunasong/models'
import { features, OAuth, profilesApi, useSelector, useThunkDispatch } from '@tunasong/redux'
import { FullPageProgress } from '@tunasong/ui-lib'
import queryString from 'query-string'
import React, { useCallback, useEffect, useState } from 'react'
import NotAuthorizedPage from '../pages/401.js'
import { TARGET_PATH } from './target-path.js'

const AuthGuard: React.FC<{ children: React.ReactNode }> = props => {
  const { children } = props
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const dispatch = useThunkDispatch()

  /** Authorize with the IDP */
  const auth = useCallback(async () => {
    localStorage.setItem(TARGET_PATH, document.location.pathname)
    /** Mode signup? */
    const params = queryString.parse(document.location.search)
    const signup = isString(params.mode) && params.mode === 'signup'
    return OAuth.authorize({ signup })
  }, [])

  /** Check if logged in. If not, redirect to federated login screen by calling authorize() */
  const [isAuthorizing, setIsAuthorizing] = useState(true)
  useEffect(() => {
    if (isLoggedIn) {
      return
    }

    /** Do not try to re-authorize if we're offline */
    const refreshExpired = isOnline()

    setIsAuthorizing(true)

    OAuth.getSession(refreshExpired)
      .then(session => {
        if (session?.idToken?.payload?.sub) {
          dispatch(features.user.actions.setAuth(session))
          // RTK Query port
          dispatch(
            profilesApi.util.prefetch(
              'loadProfile',
              {
                emailOrUserId: session.idToken.payload.sub,
              },
              {}
            )
          )
        } else {
          auth().catch(e => logger.error('auth failed', e))
        }
      })
      .catch(() => {
        /** If the above fails, we don't have a valid session, and we won't retry unless logged in again */
        OAuth.logout()
        dispatch(features.user.actions.signout())
        auth().catch(e => logger.error('auth failed', e))
      })
      .finally(() => setIsAuthorizing(false))
  }, [auth, dispatch, isLoggedIn])

  if (isLoggedIn) {
    return <>{children}</>
  }

  return isAuthorizing ? <FullPageProgress /> : <NotAuthorizedPage />
}

export default AuthGuard
