import { Box, type BoxProps } from '@mui/material'

export const FullPageLayout = ({ children, ...restProps }: BoxProps) => (
  <Box
    sx={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      color: theme => theme.vars.palette.text.primary,
    }}
    {...restProps}
  >
    {children}
  </Box>
)
