import { EntityEditor, LazyEditor, type EditorContextType } from '@tunasong/editor'
import { ChatEditor } from '@tunasong/plugin-chat/ui'
import { CollabWebsocketEditorYDoc, DynamicEntityEditor, YJSEditor } from '@tunasong/plugin-collab-yjs'
import { NoteEditor } from '@tunasong/plugin-personal-note/note-editor'

export const editorContext: EditorContextType = {
  SongEditor: LazyEditor(CollabWebsocketEditorYDoc, 'song'),
  SongComposeEditor: LazyEditor(CollabWebsocketEditorYDoc, 'songCompose'),
  /** @todo we should refactor pages to not require this */
  PageEditor: LazyEditor(DynamicEntityEditor, 'page'),
  EntityEditor: LazyEditor(EntityEditor, 'all'),
  DynamicEntityEditor: LazyEditor(DynamicEntityEditor, 'all'),
  DynamicEntityEditorWithoutPlugins: DynamicEntityEditor,
  NoteEditor: LazyEditor(NoteEditor, 'note'),
  ChatEditor: LazyEditor(ChatEditor, 'chat'),
  YJSEditor: LazyEditor(YJSEditor, 'all'),
  PerformanceEditor: LazyEditor(DynamicEntityEditor, 'performance'),
}
