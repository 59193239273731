import { type CreateTunaPlugin, type PluginConfigSet, type TunaPlugin } from '@tunasong/plugin-lib'
import { type PluginCollectionConfig, type PluginType } from './plugin-types.js'

/** @note order is important here since the LATEST plugins have precedence. So we use Map to guarantee insertion order */
export const rawPlugins = new Map(
  Object.entries({
    plate: import('@tunasong/plugin-plate'),
    core: import('@tunasong/plugin-core'),
    format: import('@tunasong/plugin-format'),
    emoji: import('@tunasong/plugin-emoji'),
    word: import('@tunasong/plugin-word'),
    list: import('@tunasong/plugin-list'),
    mention: import('@tunasong/plugin-mention'),
    poll: import('@tunasong/plugin-poll'),
    guitar: import('@tunasong/plugin-guitar'),
    bass: import('@tunasong/plugin-bass'),
    room: import('@tunasong/plugin-room'),
    block: import('@tunasong/plugin-block'),
    midi: import('@tunasong/plugin-midi'),
    musicxml: import('@tunasong/plugin-musicxml'),
    effect: import('@tunasong/plugin-effect'),
    game: import('@tunasong/games/plugin'),
    facade: import('@tunasong/plugin-facade'),
    ref: import('@tunasong/plugin-ref'),
    line: import('@tunasong/plugin-line'),
    heading: import('@tunasong/plugin-heading'),
    'smart-tab': import('@tunasong/plugin-smart-tab'),
    todo: import('@tunasong/plugin-todo'),
    chord: import('@tunasong/plugin-chord'),
    'chord-player': import('@tunasong/plugin-chord-player'),
    musicnote: import('@tunasong/plugin-music-note'),
    note: import('@tunasong/plugin-personal-note'),
    section: import('@tunasong/plugin-section'),
    song: import('@tunasong/plugin-song'),
    syllables: import('@tunasong/plugin-syllables'),
    folder: import('@tunasong/plugin-folder'),
    tab: import('@tunasong/plugin-tab'),
    score: import('@tunasong/plugin-score'),
    tunabrain: import('@tunasong/plugin-tunabrain'),
    scale: import('@tunasong/plugin-scale'),
    divider: import('@tunasong/plugin-divider'),
    link: import('@tunasong/plugin-link'),
    entitylink: import('@tunasong/plugin-entity-link'),
    embed: import('@tunasong/plugin-embed'),
    image: import('@tunasong/plugin-image'),
    audio: import('@tunasong/plugin-audio'),
    pdf: import('@tunasong/plugin-pdf'),
    video: import('@tunasong/plugin-video'),
    chat: import('@tunasong/plugin-chat'),
    page: import('@tunasong/plugin-page'),
    piano: import('@tunasong/plugin-piano'),
    playlist: import('@tunasong/plugin-playlist'),
    drawing: import('@tunasong/plugin-drawing'),
    ui: import('@tunasong/plugin-ui'),
    tracker: import('@tunasong/plugin-tracker'),
    trackerdata: import('@tunasong/plugin-trackerdata'),
    bandspace: import('@tunasong/plugin-bandspace'),
    calendar: import('@tunasong/calendar/plugins/calendar'),
    calendarevent: import('@tunasong/calendar/plugins/calendarevent'),
    comment: import('@tunasong/plugin-comment'),
    'hide-leaf': import('@tunasong/plugin-hide-leaf'),
    'single-line': import('@tunasong/plugin-single-line'),
    suggestion: import('@tunasong/plugin-suggestion'),
    unknown: import('@tunasong/plugin-unknown'),
    setlist: import('@tunasong/plugin-setlist'),
    markdown: import('@tunasong/plugin-markdown'),
    evernote: import('@tunasong/plugin-evernote'),
    table: import('@tunasong/plugin-table'),
    /** disabled for now */
    // 'inline-suggest': import('@tunasong/plugin-inline-suggest'),
    /** @todo fix this type casting */
  })
) as Map<PluginType, Promise<{ default: CreateTunaPlugin }> | TunaPlugin>

const basePlugins: PluginCollectionConfig = {
  /** Plugins that are always enabled */
  plate: true,
  core: true,
  format: true,
  emoji: true,
  word: true,
  list: true,
  table: true,
}

/** All plugins except collaboration and suggestions plugin. The later plugins have precedence */
export const allLocalPlugins: PluginCollectionConfig = {
  ...basePlugins,
  embed: true,
  mention: true,
  poll: true,
  guitar: true,
  bass: true,
  room: true,
  block: true,
  midi: true,
  musicxml: true,
  effect: true,
  game: true,
  facade: true,
  ref: true,
  line: true,
  heading: true,
  'smart-tab': true,
  todo: true,
  chord: true,
  'chord-player': true,
  musicnote: true,
  note: true,
  section: true,
  song: true,
  syllables: true,
  folder: true,
  tab: true,
  score: true,
  tunabrain: true,
  scale: true,
  divider: true,
  link: true,
  entitylink: true,
  image: true,
  audio: true,
  pdf: true,
  video: true,
  chat: true,
  page: true,
  piano: true,
  playlist: true,
  drawing: true,
  ui: true,
  tracker: true,
  trackerdata: true,
  bandspace: true,
  calendar: true,
  calendarevent: true,
  setlist: true,
  markdown: true,
  evernote: true,
}
// Used for all performance modes
const performanceBasePlugins = {
  ...basePlugins,
  line: true,
  midi: true,
  heading: true,
  section: true,
  chord: true,
  musicnote: true,
  note: true,
  song: true,
  page: true,
  folder: true,
  score: true,
  scale: true,
  divider: true,
  link: true,
  entitylink: true,
  effect: true,
  mention: true,
  pdf: true,
  musicxml: true,
  setlist: true,
}

/** The plugin-sets */
export const pluginSets: Record<PluginConfigSet, PluginCollectionConfig> = {
  none: {},
  all: { ...allLocalPlugins, suggestion: true, comment: true, unknown: true /* 'inline-suggest': true*/ },
  local: allLocalPlugins,
  custom: {
    ...basePlugins,
    block: true,
    table: true,

    // embed: true,
    // mention: true,
    // poll: true,
    // guitar: true,
    // bass: true,
    // room: true,
    // block: true,
    // midi: true,
    // musicxml: true,
    // effect: true,
    // game: true,
    // facade: true,
    // ref: true,
    // line: true,
    // heading: true,
    // 'smart-tab': true,
    // todo: true,
    // chord: true,
    // 'chord-player': true,
    // musicnote: true,
    // note: true,
    // section: true,
    // song: true,
    // syllables: true,
    // folder: true,
    // tab: true,
    // score: true,
    // tunabrain: true,
    // scale: true,
    // divider: true,
    // link: true,
    // entitylink: true,
    // image: true,
    // audio: true,
    // pdf: true,
    // video: true,
    // chat: true,
    // page: true,
    // piano: true,
    // playlist: true,
    // drawing: true,
    // ui: true,
    // tracker: true,
    // trackerdata: true,
    // bandspace: true,
    // calendar: true,
    // calendarevent: true,
    // setlist: true,
    // markdown: true,
    // evernote: true,
  },
  chat: {
    ...basePlugins,
    block: true,
    link: true,
    entitylink: true,
    chord: true,
    tab: true,
    score: true,
    image: true,
    embed: true,
    mention: true,
    todo: true,
    'single-line': true,
    game: true,
  },
  poll: {
    ...basePlugins,
    block: true,
    line: true,
    link: true,
    entitylink: true,
    chord: true,
    tab: true,
    score: true,
    image: true,
    embed: true,
    mention: true,
    todo: true,
  },
  note: {
    ...basePlugins,
    block: true,
    todo: true,
    link: true,
    note: true,
    line: {
      placeholder: 'Type your note here',
      caption: false,
    },
    markdown: true,
  },
  song: {
    ...allLocalPlugins,
    suggestion: true,
    comment: true,
    unknown: true,
    'inline-suggest': true,
  },
  songCompose: {
    ...basePlugins,
    block: true,
    section: true,
    chord: true,
    midi: true,
    song: true,
    ui: true,
  },
  page: {
    ...basePlugins,
    block: true,
    line: true,
    link: true,
    entitylink: true,
    embed: true,
    image: true,
    ref: true,
    video: true,
    folder: true,
    audio: true,
    mention: true,
    todo: true,
    heading: true,
    pdf: true,
    drawing: true,
    song: true,
    game: true,
    poll: true,
    markdown: true,
    divider: true,
  },
  setlist: {
    ...basePlugins,
    block: true,
    line: true,
    link: true,
    entitylink: true,
    embed: true,
    image: true,
    ref: true,
    video: true,
    folder: true,
    audio: true,
    mention: true,
    todo: true,
    heading: true,
    pdf: true,
    drawing: true,
    song: true,
    game: true,
    poll: true,
    setlist: true,
  },
  performance: {
    ...performanceBasePlugins,
    tab: {
      collapsable: true,
    },
  },
  'performance:guitar': {
    ...performanceBasePlugins,
    tab: {
      collapsable: false,
    },
  },
  'performance:vocals': {
    ...performanceBasePlugins,
    /**
     * @note we tried to remove the "chord" plugin from the vocal list,
     * but ended up stripping the chord content from the song...
     */
  },
}
