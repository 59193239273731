import React from 'react'

import Layout from '../components/layout.js'
import { SEO } from '../components/seo.js'
import { Typography } from '@mui/material'
import { FullPageProgress } from '@tunasong/ui-lib'

export const Head = () => <SEO title="401: Not authorized" />

const NotAuthorizedPage = () => (
  <Layout>
    <Typography variant="caption" color="secondary">
      Authorizing...
    </Typography>
    <FullPageProgress />
  </Layout>
)

export default NotAuthorizedPage
