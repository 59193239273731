import { shortcuts } from '@tunasong/ui-lib'
import { features, useThunkDispatch } from '@tunasong/redux'
import { isHotkey } from '@tunasong/models'
import React, { useEffect } from 'react'
import { useSearchDialog } from '@tunasong/search'

export const useGlobalHotkeys = () => {
  const dispatch = useThunkDispatch()

  const { dialog, setOpen } = useSearchDialog()

  useEffect(() => {
    const handleKey = (ev: KeyboardEvent) => {
      if (isHotkey(shortcuts.Save.hotkey)(ev)) {
        ev.preventDefault()
        dispatch(
          features.notifications.actions.setAlert({
            severity: 'success',
            message: 'Your document is saved automatically.',
          })
        )
      }
      if (isHotkey(shortcuts.Search.hotkey)(ev)) {
        ev.preventDefault()
        setOpen(true)
      }
    }
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [dispatch, setOpen])

  return { globalHotkeyModals: [<React.Fragment key="search-dialog">{dialog}</React.Fragment>] }
}

export default useGlobalHotkeys
