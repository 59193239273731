import type { PluginConfigSet, PluginRepository, TunaPlugin } from '@tunasong/plugin-lib'
import { configurePlugins } from './plugin-collection.js'
import { type PluginCollectionConfig } from './plugin-types.js'
import { pluginSets } from './plugins.js'

/** We want to dynamically load plugins when used */

export class Repository implements PluginRepository {
  pluginCollections: Partial<Record<PluginConfigSet, TunaPlugin[]>> = {}
  loadCollection = async (type: PluginConfigSet | PluginCollectionConfig) => {
    const isConfigSet = typeof type === 'string'
    if (isConfigSet && this.pluginCollections[type]) {
      const collection = this.pluginCollections[type]
      return collection ?? []
    }
    const config = typeof type === 'string' ? pluginSets[type] : type

    const plugins = await configurePlugins(config)

    if (isConfigSet) {
      this.pluginCollections[type] = plugins
    }
    return plugins as TunaPlugin[]
  }
}

export const pluginRepository = new Repository()
