/** Sync with Service Worker */

import { logger } from '@tunasong/models'
import { features, profilesApi, updateEntitiesStore, useStore, useThunkDispatch } from '@tunasong/redux'
import type { Persisted } from '@tunasong/schemas'
import { type Entity, type Profile } from '@tunasong/schemas'
import { useCallback, useEffect } from 'react'
import { SWCacheName } from './caches.js'
import { type SWSyncMessage } from './message.js'

export const useSWSync = () => {
  const dispatch = useThunkDispatch()
  const { getState } = useStore()

  const updateRTKQueryEntity = useCallback(
    (entities: Persisted<Entity>[], removedIds: string[]) => {
      updateEntitiesStore({ entities, dispatch, state: getState(), removedIds })
    },
    [dispatch, getState]
  )
  useEffect(() => {
    const serviceWorker = navigator?.serviceWorker
    if (!(serviceWorker && 'caches' in window)) {
      return
    }
    const handleSWMessage = async (msg: MessageEvent<{ payload: SWSyncMessage }>) => {
      const { cacheName, eTag, updatedURL, removedIds } = msg.data.payload

      logger.debug(`Service Worker Sync: ${cacheName} - ${updatedURL}`, msg.data.payload)

      /** Match the data from the cache */
      const data = await caches.match(updatedURL, { cacheName })
      if (!data) {
        throw new Error(`Cache entry not found, but it was just updated: ${cacheName} - ${updatedURL}`)
      }

      const json = (await data.json()) as Persisted<Entity> | Persisted<Entity>[]

      /** Check for 401 and remove from cache and Redux  */
      if (cacheName === SWCacheName.ENTITIES && data.status === 401) {
        const cache = await caches.open(cacheName)
        await cache.delete(updatedURL)
        return
      }

      /** @todo the problem here is that we may have am updated version in Redux, and we'll overwrite a more specific non-partial version */
      if (cacheName === SWCacheName.ENTITIES) {
        /** Dispatch updated data to Redux */
        if (Array.isArray(json)) {
          updateRTKQueryEntity(json, removedIds)
          logger.debug(
            `Updated ${json.length} entities from Service Worker (triggered by ${updatedURL})`,
            json.map(e => e.id)
          )
        } else {
          /** Single entity. Update the eTag */
          if (eTag) {
            dispatch(features.entities.actions.setEtag({ id: json.id, eTag }))
          }
          /** RTK QUery */
          updateRTKQueryEntity([json], removedIds)
        }
      } else if (cacheName === SWCacheName.PROFILES) {
        const profiles = (Array.isArray(json) ? json : [json]) as Persisted<Profile>[]
        for (const profile of profiles) {
          dispatch(profilesApi.util.upsertQueryData('loadProfile', { emailOrUserId: profile.userId }, profile))
        }
      }
      /** Additional handlers here */
    }

    serviceWorker.addEventListener('message', handleSWMessage)
    return () => {
      serviceWorker.removeEventListener('message', handleSWMessage)
    }
  }, [dispatch, updateRTKQueryEntity])
}
