import { EditorsContext } from '@tunasong/editor'
import { PluginContext } from '@tunasong/plugin-lib'
import React, { type FC } from 'react'
import { editorContext } from './editors.js'
import { pluginRepository } from './plugin-repository.js'

/** Inject classes and values in the relevant context(s) */
export const DIContainer: FC<{ children?: React.ReactNode }> = ({ children }) => (
  <PluginContext.Provider value={pluginRepository}>
    <EditorsContext.Provider value={editorContext}>{children}</EditorsContext.Provider>
  </PluginContext.Provider>
)
