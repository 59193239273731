import { ErrorMessage, Profile, RootMain } from '@tunasong/tunasong-ui'
import { createBrowserRouter, SuspenseBusy, withSuspense } from '@tunasong/ui-lib'
import React from 'react'
import {
  LazyEmbed,
  LazyErrorBoundary,
  LazyIndexPage,
  LazyLogin,
  LazyPage,
  LazyTermsOfService,
  LazyWaitlistPage,
} from './lazy-routes.js'
import { pageChrome } from './page-chrome.js'
import { FavoritiesPage } from './pages/favorities.js'
import { RouterAppChrome } from './router-entity-container.js'
import { RouterAppGroupChrome } from './router-entity-group-container.js'
import { AudioEngine, AudioMixer } from '@tunasong/audio-ui'
import AppContainer from './app-container.js'
import { ConfigService } from '@tunasong/redux'

/**
 * @todo check auth https://www.robinwieruch.de/react-router-authentication/
 * @todo we can use the route loader to load the entities
 * @todo implement basename
 * */

const errorElement = <LazyErrorBoundary />

const configService = new ConfigService()
const audioEngine = new AudioEngine({ configService })

const router = createBrowserRouter(
  [
    { path: '', element: <LazyIndexPage />, index: true },
    {
      path: 'error',
      children: [
        {
          path: '404',
          element: <ErrorMessage message="Page is not found" />,
        },
      ],
    },

    { path: 'terms', element: <LazyTermsOfService /> },
    { path: 'waitlist', element: <LazyWaitlistPage /> },
    /** Public app paths */
    {
      path: 'embed',
      element: (
        <AppContainer
          audioEngine={audioEngine}
          configService={configService}
          component={LazyEmbed}
          audio={true}
          theme="light"
        />
      ),
    },
    {
      path: 'page/:slug',
      element: <AppContainer audioEngine={audioEngine} configService={configService} component={LazyPage} />,
    },
    /** Main app below */
    {
      path: 'app/',
      errorElement,
      children: [
        {
          path: '',
          index: true,
          element: (
            <AppContainer
              audioEngine={audioEngine}
              configService={configService}
              component={pageChrome(RootMain)}
              audio={true}
            />
          ),
        },
        {
          path: 'login',
          element: (
            <AppContainer audioEngine={audioEngine} configService={configService} component={LazyLogin} auth={false} />
          ),
        },
        {
          path: 'logout',
          element: (
            <AppContainer
              audioEngine={audioEngine}
              configService={configService}
              component={withSuspense(
                React.lazy(async () => import('./auth/logout.js')),
                <SuspenseBusy />
              )}
              auth={false}
            />
          ),
        },
        {
          path: 'e/:entityId/*',
          element: (
            <AppContainer
              audioEngine={audioEngine}
              configService={configService}
              component={RouterAppChrome}
              audio={true}
            />
          ),
        },
        {
          // group
          path: 'g/:entityId/*',
          element: (
            <AppContainer
              audioEngine={audioEngine}
              configService={configService}
              component={RouterAppGroupChrome}
              audio={true}
            />
          ),
        },
        {
          path: 'mixer',
          element: (
            <AppContainer
              audioEngine={audioEngine}
              configService={configService}
              component={pageChrome(AudioMixer, {
                container: true,
                containerProps: {
                  sx: { pt: 2 },
                },
              })}
              audio={true}
            />
          ),
        },
        {
          path: 'profile',
          element: (
            <AppContainer
              audioEngine={audioEngine}
              configService={configService}
              component={pageChrome(Profile)}
              audio={true}
            />
          ),
        },
        {
          path: 'favorites',
          element: (
            <AppContainer
              audioEngine={audioEngine}
              configService={configService}
              component={pageChrome(FavoritiesPage, { container: true })}
              audio={true}
            />
          ),
        },

        {
          path: 'labs',
          element: (
            <AppContainer
              audioEngine={audioEngine}
              configService={configService}
              component={withSuspense(
                React.lazy(async () => import('@tunasong/labs')),
                <SuspenseBusy />
              )}
              audio={true}
            />
          ),
        },

        // @deprecated - don't use below
        {
          path: ':entityType/:entityId/*',
          element: (
            <AppContainer
              audioEngine={audioEngine}
              configService={configService}
              component={RouterAppChrome}
              audio={true}
            />
          ),
        },
        {
          path: 'entities/:entityId/*',
          element: (
            <AppContainer
              audioEngine={audioEngine}
              configService={configService}
              component={RouterAppChrome}
              audio={true}
            />
          ),
        },
      ],
    },
    { path: '*', element: <ErrorMessage message="Page is not found" /> },
  ],
  {
    /** So we can omit all /app/* in Links */
    // basename: '/app',
  }
)

export default router
