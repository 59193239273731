import { type BoxProps } from '@mui/material'
import { AppChrome } from '@tunasong/tunasong-ui'
import { ContentContainer, type ContentContainerProps } from '@tunasong/ui-lib'
import type { FC } from 'react'

/** Router wrapper for the AppChrome */
export const pageChrome =
  <T,>(
    Component: FC<T>,
    options: {
      container?: boolean
      containerProps?: ContentContainerProps
      contentBoxProps?: BoxProps
    } = {}
  ) =>
  (props: T & JSX.IntrinsicAttributes) => (
    <AppChrome>
      {options.container ? (
        <ContentContainer {...options?.containerProps} contentBoxProps={options.contentBoxProps}>
          <Component {...props} />
        </ContentContainer>
      ) : (
        <Component {...props} />
      )}
    </AppChrome>
  )
