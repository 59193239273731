/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { type FC } from 'react'
import { Box } from '@mui/material'

const Layout: FC<{ children?: React.ReactNode }> = ({ children }) => (
  /** We do this to load the CSS - see top */
  <Box>{children}</Box>
)

export default Layout
