import { Typography } from '@mui/material'
import { FavoritesList, useNavigateToEntity } from '@tunasong/ui-lib'
import { type FC } from 'react'

export interface FavoritiesPateProps {}

export const FavoritiesPage: FC<FavoritiesPateProps> = props => {
  const {} = props
  const navigateToEntity = useNavigateToEntity()
  return (
    <>
      <Typography variant="h1" sx={{ textAlign: 'center', color: theme => theme.vars.palette.text.secondary }}>
        Favorites
      </Typography>
      <FavoritesList onOpen={navigateToEntity} />
    </>
  )
}
