import { GlobalStyles, useTheme } from '@mui/material'

export const TunaGlobalStyles = () => {
  const theme = useTheme()
  const styles = {
    '::-moz-selection': { background: theme.vars.palette.action.selected },
    '::selection': { background: theme.vars.palette.action.selected },

    'body, html': {
      backgroundColor: theme.vars.palette.background.default,
      '--background-color': theme.vars.palette.background.default,
      fontFamily: theme.typography.fontFamily,
    },
  }

  return <GlobalStyles styles={styles} />
}

export default TunaGlobalStyles
