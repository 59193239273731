import { logger } from '@tunasong/models'
import { pushApi, useSelector } from '@tunasong/redux'
import { useMounted } from '@tunasong/ui-lib'
import { detect } from 'detect-browser'
import { useEffect } from 'react'
import { getSubscription, subscribeUser } from '../push/index.js'
import { arrayBufferToBase64 } from './base64.js'

export const useRegisterWebPush = () => {
  const token = useSelector(state => state.user.authData?.idToken.jwtToken)

  const mounted = useMounted()

  const { currentData: { publicKey } = {} } = pushApi.useGetPushPublicKeyQuery()
  const [registerPushSubscription] = pushApi.useLazyRegisterPushSubscriptionQuery()

  useEffect(() => {
    if (!publicKey) {
      return
    }
    const sub = async () => {
      if (!mounted.current) {
        return
      }

      let subscription: PushSubscription | null = null
      try {
        const existingSub = await getSubscription()
        if (existingSub) {
          return
        }

        subscription = await subscribeUser(publicKey)

        const dh = subscription.getKey('p256dh')
        const p256dh = dh ? arrayBufferToBase64(dh) : (null as never)
        const a = subscription.getKey('auth')
        const auth = a ? arrayBufferToBase64(a) : (null as never)

        const d = detect()
        const response = await registerPushSubscription(
          {
            url: subscription.endpoint,
            deviceName: `${d?.name ?? 'Unknown'}-${d?.version ?? 'Unknown'}-${d?.type ?? 'Unknown'}-${
              d?.os ?? 'Unknown'
            }`,
            p256dh,
            auth,
          },
          true
        ).unwrap()
        logger.debug('Registered push subscription: ', subscription, response)
      } catch (error) {
        logger.error('Error registering push subscription', error)
        subscription?.unsubscribe()
      }
    }
    sub()
  }, [mounted, publicKey, registerPushSubscription, token])
}
