import { CSP } from '@tunasong/ui-lib'
import { Provider, rootStore } from '@tunasong/redux'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { App } from './app.js'
import { SEO } from './components/seo.js'

// const rootStore = configureStore()

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!)

const helmetContext = {}

root.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <SEO title="Tunasong" />
      <CSP domainName="tunasong.com" />
      <Provider store={rootStore}>
        <App />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
)
