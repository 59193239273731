/** Register mime types from plugins in Redux */

import { features } from '@tunasong/redux'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { pluginRepository } from '../di/plugin-repository.js'
import { getMimeTypeMapping } from '@tunasong/plugin-lib'

/** @note must be run inside of a PluginContext */
export const useRegisterMimeTypesFromPlugins = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    pluginRepository.loadCollection('all').then(plugins => {
      const result = getMimeTypeMapping(plugins)
      dispatch(features.storage.actions.registerMimeTypeMappings(result))
    })
    return () => {
      dispatch(features.storage.actions.registerMimeTypeMappings(null))
    }
  }, [dispatch])
}
