/** Cache names for the Service Worker */

export enum SWCacheName {
  DEFAULT = 'tunasong-default',
  PAGES = 'tunasong-pages',
  ENTITIES = 'tunasong-entities',
  PROFILES = 'tunasong-profiles',
  API_MISC = 'tunasong-api-misc',
  AUDIO_VIDEO = 'tunasong-audio-video',
  DATA = 'tunasong-data',
  DOCS = 'tunasong-docs',
  USER_DATA = 'tunasong-user-data',
  FONTS = 'tunasong-fonts',
  IMAGES = 'tunasong-images',
  WORDS = 'tunasong-words',
  GOOGLE = 'tunasong-google-users',
  /** ML models */
  MODELS = 'tunasong-models',
  INSTRUMENTS = 'tunasong-instruments',
  SEARCH = 'tunasong-search',
  NLP = 'tunasong-nlp',
}

export const inCache = async (cacheName: SWCacheName, url: string) => {
  if ('caches' in window) {
    const match = await caches.match(url, { cacheName })
    return Boolean(match)
  } else {
    return false
  }
}

export const entityPaths = ['https://tunasong.com/api/entities', 'https://tunasong.com/api/social']

export const isCachableEntityPath = (url: string) =>
  entityPaths.some(path => url.startsWith(path)) &&
  // @todo cache edges queries. we need to sync with Redux...
  !url.includes('/edges') &&
  !url.includes('/backlinks') &&
  !url.includes('?fields') &&
  !url.includes('&fields') &&
  !url.endsWith('/download')

export const isCachableEntity = (request: Request) =>
  request.headers.get('cache-control') !== 'no-cache' && isCachableEntityPath(request.url)
