import { logger } from '@tunasong/models'

export const getSubscription = async () => {
  const reg = await navigator.serviceWorker.getRegistration()
  if (!reg) {
    return null
  }
  /** Safari sometimes has reg defined but pushManager undefined */
  if (!('pushManager' in reg)) {
    logger.error('PushManager not available on service worker registration', reg)
    return null
  }
  const sub = await reg.pushManager.getSubscription()
  return sub
}
